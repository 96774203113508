var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pp-frame',{scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('v-card',[_c('v-progress-linear',{attrs:{"color":_vm.progressColor,"value":(_vm.qSections && _vm.qSections.percent) || 0,"indeterminate":_vm.loadingCompletedSections,"height":"10"}}),_c('v-card-title',[(_vm.loadingCompletedSections || !_vm.qSections)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Loading'))+"... ")]):_c('span',[_vm._v(" "+_vm._s((_vm.qSections && _vm.qSections.percent) || 0)+"% "+_vm._s(_vm.$t('complete'))+" ")])]),_c('v-divider'),_c('v-card-text',{staticClass:"d-flex flex-column align-start"},[_c('h3',{staticClass:"blue-grey--text"},[_c('p',[_vm._v(_vm._s(_vm.$t('Registration')))])]),_vm._l((_vm.pages.register),function(pageName,page,pIndex){return _c('router-link',{key:page,staticClass:"d-flex align-center text-decoration-none mb-2",class:pIndex > _vm.lastRegSect && 'no-cursor',attrs:{"to":("/register/" + page)}},[(pIndex < _vm.lastRegSect)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]):(pIndex === _vm.lastRegSect)?_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-pencil ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert ")]),_c('span',{staticClass:"ml-1",class:{
              'success--text': pIndex < _vm.lastRegSect,
              'info--text': pIndex === _vm.lastRegSect,
              'error--text': pIndex > _vm.lastRegSect
            }},[_vm._v(" "+_vm._s(_vm.$t(pageName))+" ")])],1)})],2),_c('v-divider'),(_vm.qSections)?_c('v-card-text',[_c('h3',{staticClass:"blue-grey--text"},[_c('p',[_vm._v(_vm._s(_vm.$t('Questionnaire')))])]),_vm._l((_vm.pages.questionnaire),function(pageName,page){return _c('router-link',{key:page,staticClass:"d-flex align-center text-decoration-none mb-2",class:_vm.qSections[_vm.$lodash.camelCase(page)] && 'no-cursor',attrs:{"to":("/questionnaire/" + page),"disabled":""}},[(_vm.qSections[_vm.$lodash.camelCase(page)])?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert ")]),_c('span',{staticClass:"ml-1",class:{
              'success--text': _vm.qSections[_vm.$lodash.camelCase(page)],
              'error--text': !_vm.qSections[_vm.$lodash.camelCase(page)]
            }},[_vm._v(" "+_vm._s(_vm.$t(pageName))+" ")])],1)})],2):_vm._e()],1)]},proxy:true}])},[_c('h1',{staticClass:"blue-grey--text text-left mt-md-4"},[_c('p',[_vm._v(_vm._s(_vm.translate('Welcome, {person}!', { person: _vm.person, gender: _vm.gender })))])]),_c('div',{staticClass:"secondary--text text-left"},[_vm._v(" "+_vm._s(_vm.translate('Welcome to the secure patient portal!', { gender: _vm.gender }))),_c('br'),_vm._v(" "+_vm._s(_vm.translate(" We're excited that you have taken the first steps toward improving your sleep quality and health. "))+" "+_vm._s(_vm.translate(" Treating snoring and sleep apnea improves your quality of life and can dramatically improve your health and well-being. "))+" "+_vm._s(_vm.translate(" At the office of {practice}, we work hard to make sure you receive the best possible treatment. ", { practice: _vm.practice }))),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.translate(" Please check the status box on the left side of this page to make sure your patient profile is 100% complete. "))),_c('br'),_vm._v(" "+_vm._s(_vm.translate(" Click on any incomplete highlighted fields to answer them. "))),_c('br'),_vm._v(" "+_vm._s(_vm.translate(" After your profile is 100% complete, we will be ready to see you at your next visit! "))+" ")]),(_vm.qSections && (_vm.qSections.percent !== 100))?_c('div',{staticClass:"d-flex mt-6"},[(_vm.qSections.registerCompleted)?_c('v-btn',{staticClass:"mx-auto",attrs:{"color":"primary","rounded":"","to":"/questionnaire"}},[_vm._v(" "+_vm._s(_vm.$t('Continue Questionnaire'))+" ")]):_c('v-btn',{staticClass:"mx-auto",attrs:{"color":"primary","rounded":"","to":"/register"}},[_vm._v(" "+_vm._s(_vm.lastRegSect ? _vm.$t('Continue Registration') : _vm.$t('Start Here'))+" ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }