<template>
  <pp-frame>
    <template #message>
      <v-card>
        <v-progress-linear
          :color="progressColor"
          :value="(qSections && qSections.percent) || 0"
          :indeterminate="loadingCompletedSections"
          height="10"
        />

        <v-card-title>
          <span v-if="loadingCompletedSections || !qSections">
            {{ $t('Loading') }}...
          </span>
          <span v-else>
            {{ (qSections && qSections.percent) || 0 }}% {{ $t('complete') }}
          </span>
        </v-card-title>

        <v-divider />

        <v-card-text class="d-flex flex-column align-start">
          <h3 class="blue-grey--text">
            <p>{{ $t('Registration') }}</p>
          </h3>

          <router-link
            v-for="(pageName, page, pIndex) in pages.register"
            :key="page"
            class="d-flex align-center text-decoration-none mb-2"
            :class="pIndex > lastRegSect && 'no-cursor'"
            :to="`/register/${page}`"
          >
            <v-icon
              v-if="pIndex < lastRegSect"
              color="success"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-else-if="pIndex === lastRegSect"
              color="info"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-else
              color="error"
            >
              mdi-alert
            </v-icon>
            <span
              class="ml-1"
              :class="{
                'success--text': pIndex < lastRegSect,
                'info--text': pIndex === lastRegSect,
                'error--text': pIndex > lastRegSect
              }"
            >
              {{ $t(pageName) }}
            </span>
          </router-link>
        </v-card-text>

        <v-divider />

        <v-card-text v-if="qSections">
          <h3 class="blue-grey--text">
            <p>{{ $t('Questionnaire') }}</p>
          </h3>

          <router-link
            v-for="(pageName, page) in pages.questionnaire"
            :key="page"
            class="d-flex align-center text-decoration-none mb-2"
            :class="qSections[$lodash.camelCase(page)] && 'no-cursor'"
            :to="`/questionnaire/${page}`"
            disabled
          >
            <v-icon
              v-if="qSections[$lodash.camelCase(page)]"
              color="success"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-else
              color="error"
            >
              mdi-alert
            </v-icon>

            <span
              class="ml-1"
              :class="{
                'success--text': qSections[$lodash.camelCase(page)],
                'error--text': !qSections[$lodash.camelCase(page)]
              }"
            >
              {{ $t(pageName) }}
            </span>
          </router-link>
        </v-card-text>
      </v-card>
    </template>

    <h1 class="blue-grey--text text-left mt-md-4">
      <p>{{ translate('Welcome, {person}!', { person, gender }) }}</p>
    </h1>

    <div class="secondary--text text-left">
      {{ translate('Welcome to the secure patient portal!', { gender }) }}<br>
      {{ translate(`
      We're excited that you have taken the first steps toward improving your
      sleep quality and health.
      `) }}
      {{ translate(`
      Treating snoring and sleep apnea improves your quality of life and can
      dramatically improve your health and well-being.
      `) }}
      {{ translate(`
      At the office of {practice}, we work hard to make sure you receive
      the best possible treatment.
      `, { practice }) }}<br>
      <br>
      {{ translate(`
      Please check the status box on the left side of this page to make sure
      your patient profile is 100% complete.
      `) }}<br>
      {{ translate(`
      Click on any incomplete highlighted fields to answer them.
      `) }}<br>
      {{ translate(`
      After your profile is 100% complete, we will be ready to see you at your
      next visit!
      `) }}
    </div>

    <div
      v-if="qSections && (qSections.percent !== 100)"
      class="d-flex mt-6"
    >
      <v-btn
        v-if="qSections.registerCompleted"
        class="mx-auto"
        color="primary"
        rounded
        to="/questionnaire"
      >
        {{ $t('Continue Questionnaire') }}
      </v-btn>
      <v-btn
        v-else
        class="mx-auto"
        color="primary"
        rounded
        to="/register"
      >
        {{ lastRegSect ?
          $t('Continue Registration') :
          $t('Start Here')
        }}
      </v-btn>
    </div>
  </pp-frame>
</template>

<script>
import { computed } from '@vue/composition-api'
import upperFirst from 'lodash/upperFirst'
import { useProfile } from '@/use/profile'
import { pages } from '@/constants'

export default {
  name: 'Home',
  setup: () => {
    const {
      primaryProfile,

      lastRegSect,
      qSections,
      loadingCompletedSections
    } = useProfile()

    const person = computed(() => primaryProfile.value &&
      upperFirst(primaryProfile.value.firstname))

    const gender = computed(() => primaryProfile.value &&
      (primaryProfile.value.gender.toLowerCase() === 'female'))

    const practice = computed(() => primaryProfile.value &&
      upperFirst(primaryProfile.value.doctor.practice))

    const progressColor = computed(() => !qSections.value ? 'warning'
      : qSections.value.percent >= 90 ? 'success'
        : qSections.value.percent >= 80 ? 'primary'
          : qSections.value.percent >= 40 ? 'warning' : 'error')

    return {
      pages,

      person,
      gender,
      practice,
      primaryProfile,

      progressColor,
      lastRegSect,
      qSections,
      loadingCompletedSections
    }
  }
}
</script>
